.about_text {
  font-size: 2rem;
  line-height: 2.3rem;
  color: #366b7c;
}
.about_description{
  color: #366b7c;
}
.about ul li {
  color: rgb(90, 90, 90);
}

@media (min-width: 25.6rem) {
  .about_text {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }
}
@media (min-width: 35rem) {
  .about_text {
    font-size: 3.5rem;
    line-height: 3rem;
  }
}
@media (min-width: 50rem) {
  .about_text {
    font-size: 4rem;
    line-height: 3.5rem;
  }
  .about_description{
    font-size: 1.3rem;
  }
}
/* Portrait */
@media (min-height: calc(1.5 * 100vw)) and (min-width: 50rem) {
   .about_text{
    font-size: 5rem;
    line-height: 5rem;
   }
  .about_description{
    font-size: 2.5rem;    
  } 
}

/* Landscape */
@media(min-width: 80rem) and (min-aspect-ratio: 1/1){
  .about_description{
    font-size: 1.7rem;
  }
}
