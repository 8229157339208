.home {
  position: relative;
  display: grid;
  height: calc(100vh - 60px);
  max-width: 100vw;
  background-color: #f1f1f1;
  overflow: hidden;
}
.homebox {
  position: relative;
  padding: 2%;
  display: grid;
  justify-content: center;
  align-items: center;
  max-width: 38rem;
  max-height: 75vh;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr; /* TODO: Look into setting this to one 1fr row for horizontal view*/
  column-gap: 1rem;
}
.shapes_container{
  position: relative;
  overflow: hidden;
}
.shapes_container__left{
  position: absolute;
  top: 5%;
  z-index: 2;
  background:
      /* linear-gradient(360deg, #f1f1f1 0%, rgb(237, 109, 44) 85%),  */
      url("../../assets/images/marigold.png") 13% 100%/auto no-repeat;
  width: 90%;
  height: 50%; 
  border-radius: 0 45% 45% 0;
  overflow: hidden;
  background-blend-mode: multiply;

}
.designer_image{
  position: absolute;
  bottom: -35%;
  left: 0%;
  height: 100%;
  width: 100%;
  background: url("../../assets/images/annaastle.png"); 
  background-repeat: no-repeat;
  z-index: 3;
}

.shapes_container__right{
  position: absolute;
  top: 50%;
  left: 40%;
  background:  linear-gradient(360deg, rgba(237,109,44,1) 0%, #f1f1f1 80%);
  width: 75%; 
  height: 55%; 
  border-radius: 60%;
  overflow: hidden;
}

.homebox_about {
  color: white;
  width: 70%;
  position: absolute;
  left: 5%;
  top: 10vw;
}

.homebox_tech {
  position: absolute;
  bottom: 0%;
  left: 1%;
  background-color: rgba(255, 255, 255, 0.603);
  border-radius: 15px;
  z-index: 3;
}

@media (min-width: 40rem) {
  .designer_image{
    left: 45%;
    bottom: -55%;
  }
}

@media (min-width: 50rem) {
  .home{
      height: 100vh;
  }
  .designer_image{
    left: 48%;
    bottom: -60%;
  }
  .homebox_about{
    width: 50%;
  }
}

/* Portrait */
@media(max-aspect-ratio: 1/1) and (max-width: 50rem){
  .home{
    height: calc(100vh - 70px);
  }
  .shapes_container__left{
    top: -4%;
    width: 120%;
    height: 55%; 
    border-radius: 0 45% 45% 35%;
        background:
      /* linear-gradient(360deg, #f1f1f1 0%, rgb(237, 109, 44) 80%),  */
      url("../../assets/images/marigold.png") 70% 90%/25% no-repeat;
}
  .shapes_container__right{
    top: 60%;
    left: 23%;
    height: 40%;
    width: 100%;
    border-radius: 95% 0% 0% 0%;
}
  .homebox_tech{
    bottom: 2%;
  }

}
@media (min-height: calc(2 * 100vw)) {
  .shapes_container__left{
    height: 42%;
  }
  .homebox_about{
    width: 85%;
    top: 4vh;
  }
  .designer_image{
    top: 45%;
  }
}
@media (min-height: calc(2.2 * 100vw)) {
 .shapes_container__left{
    height: 35%;
  }
}


/* Landscape */
@media (min-aspect-ratio: 1/1){
  .home{
    height: calc(100vh - 50px);
  }
  .shapes_container__left{
      top: 10%;
      width: 55%;
      height: 75%;
      background:
        /* linear-gradient(180deg, rgb(233, 212, 188) 0%, rgb(237, 109, 44) 100%),  */
        url("../../assets/images/marigold.png") 90% 35%/30% no-repeat;
  }
  .shapes_container__right{
      width: 40%;
      height: 90%;
      border-radius: 50%; 
      top: 35%;
      left: 53%;
      background:  linear-gradient(360deg, rgba(237,109,44,1) 0%, #f1f1f1 80%);
  }
  .homebox_about{
    width: 70%;
    top: 20%;
  }
  .designer_image{
    left: 48%;
    bottom: -15%;
  }
  .homebox_tech{
  bottom: 14%;
}
}

@media(min-width: 80rem) and (min-aspect-ratio: 1/1){
  .home{
      height: calc(100vh - 20px);
    }
    .shapes_container__left{
   background:
        /* linear-gradient(360deg, #f1f1f1 0%, rgb(237, 109, 44) 85%),  */
        url("../../assets/images/marigold.png") 13% 110%/auto no-repeat;
  }
  .designer_image{
    left: 48%;
    bottom: -35%;
  }
   .homebox_about{
    width: 90%;
    top: 10%;
  }
  .homebox_tech{
    left: 19%;
  }
}
