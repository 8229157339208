footer {
  display: grid;
  justify-content: center;
  gap: 1rem;
  margin-top: 6.3rem;
  background-color: #f1f1f1;
  padding: 1.9rem;
}

.contact {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
}
.viewCode {
  display: block;
  margin: 0 auto;
  font-size: 0.9rem;
  color: #777;
  text-decoration: none;
  padding: 0.6rem;
}
