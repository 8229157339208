/* @import url("https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:wght@400;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Cantata+One&family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* html:not([data-scroll="0"]) {
  color: #0c77ff;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
html:not([data-scroll="0"]) header {
  background-color: white;
}
body {
  font-family:  "Cantata One", serif;
  color: #585858;
}

img {
  display: block;
  max-width: 100%;
  max-height: auto;
}

.decorative,
h1,
h2 {
  font-family: "Lobster Two", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #0c77ff;
}

h1 {
  margin: 0.5rem 0 1rem 0;
  line-height: 3.5rem;
}

main {
  padding: 2%;
  margin: 0 auto;
}
li {
  list-style: none;
}

.page-break {
  opacity: 0;
}
.gray {
  color: #555;
}
.font-medium {
  font-size: 2.3rem;
}
.font-large {
  font-size: 2.8rem;
}
.font-xlarge {
  font-size: 3.8rem;
}

.fadeIn {
  animation: fadeIn 1s forwards;
}
.moveDownFadeOut {
  animation: moveDownFadeOut 2s ease-in 5s infinite;
}

.hidden {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveDownFadeOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.8;
  }
  75% {
    opacity: 1;
    transform: translateY(8vh);
  }
  99% {
    transform: translateY(8vh) scale(1.5);
  }
  100% {
    opacity: 0;
    transform: translateY(8vh);
  }
}
